import "es6-shim";
import "scrollingelement";
import anime from 'animejs/lib/anime.es.js';
import * as Fresco from '@staaky/fresco';
import Splide from '@splidejs/splide';
import MicroModal from 'micromodal';
import overlayScrollbars from 'overlayScrollbars/js/jquery.overlayScrollbars.min.js';
import device from 'current-device';


import { ready } from './_ready.js';
import * as Nav from "./_nav.js";

ready(function() {

    /********************/
    /********************/
    /********************/
    /********************/
    //NAV

    Nav.initNav();

    /*END NAV***************/
    /********************/
    /********************/
    /********************/


    // GAL
    if ($(".galerieSplide").length > 0) {
        new Splide('.galerieSplide', {
            autoWidth: true,
            type: 'loop',
            height: '31.25rem',
            gap: '1rem',
            breakpoints: {
                980: {
                    height: '20rem'
                }
            }
        }).mount();;
    }


    //modal scroll
    if ($(".js-openModal").length > 0) {
        MicroModal.init({
            disableFocus: true,
            disableScroll: true,
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            onClose: modal => {
                $('body').removeClass("modalOpen");
            },
            onShow: modal => {
                $('body').addClass("modalOpen");

            }
        });
    }

    let modalSpec = $(".modal__inside").overlayScrollbars({ className: "os-theme-dark os-green", paddingAbsolute: true });

    // ACCORDEONS

    $('.qubely-accordion-panel').on('click', function() {
        let tThis = $(this).parent();
        if (tThis.parent().parent().attr('data-item-toggle') == 'true') {

            let actives = $('.qubely-accordion-item.qubely-accordion-active');
            let toOpen = tThis;

            if (actives.length > 0) {
                if (toOpen.hasClass('qubely-accordion-active')) {
                    toOpen.find(".qubely-accordion-body").slideUp();
                    toOpen.removeClass('qubely-accordion-active');
                } else {
                    actives.find(".qubely-accordion-body").slideUp(400, function() {
                        actives.removeClass('qubely-accordion-active');
                        toOpen.find(".qubely-accordion-body").slideDown();
                        let eTop = parseInt(toOpen.offset().top) - 150;
                        scrollTo(eTop);
                        toOpen.addClass('qubely-accordion-active');
                    });
                }
            } else {
                toOpen.find(".qubely-accordion-body").slideDown(400, function() {
                    let eTop = parseInt(toOpen.offset().top) - 150;
                    scrollTo(eTop);
                });

                toOpen.addClass('qubely-accordion-active');
            }
            return false;
        }
    });


});


function scrollTo(eTop) {
    let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
    //let eTop = parseInt($(".temoignages").offset().top) - 64;
    anime({
        targets: scrollElement,
        scrollTop: eTop,
        duration: 400,
        easing: 'cubicBezier(.215,.61,.355,1)'
    });
}

/*
//scroll
                    let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
                    let eTop = parseInt($(".temoignages").offset().top) - 64;
                    anime({
                        targets: scrollElement,
                        scrollTop: eTop,
                        duration: 350,
                        easing: 'cubicBezier(.215,.61,.355,1)'
                    });
                    */