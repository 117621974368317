import anime from 'animejs/lib/anime.es.js';

export function initNav(){

    //Si y'a des sous menus, on rajoute un lien vers la page parente dans le sous-menu
    $(".js-openSsNav").each(function(i){
        let mainLink = $(this);
        let ssNav = mainLink.next(".smsNavBar__subNav__ul");
        let newLink = mainLink.clone();
        let li = $("<li class='menu-item nav__subNav__li'></li>");
        newLink.removeClass("smsNavBar__link js-openSsNav").addClass("smsNavBar__subNav__link parentSubNav");
        li.append(newLink);
        li.prependTo(ssNav);
    });


    $(".js-openNav").on('click', function(e) {
        e.stopImmediatePropagation();

        anime({
            targets: '.js-menu',
            translateX: ['100%', '0'],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)',
            complete: function(anim) {
                $("#menuSms").addClass("opened");
                $(".js-menu").attr('aria-hidden', false);
                $(".js-openNav").each(function() {
                    $(this).attr('aria-expanded', true);
                });
            }
        });
        return false;
    });

    $(".js-closeNav").on('click', function(e) {
        e.stopImmediatePropagation();
        if (!$('#menuSms').hasClass('opened')) return false;
        anime({
            targets: '.js-menu',
            translateX: ['0', '100%'],
            duration: 500,
            easing: 'cubicBezier(.215,.61,.355,1)',
            complete: function(anim) {
                $("#menuSms").removeClass("opened");
                $(".js-menu").attr('aria-hidden', true);
                $(".js-openNav").each(function() {
                    $(this).attr('aria-expanded', false);
                });
            }
        });
        return false;
    });

    $(".js-openSsNav").on("click", function(e) {
        e.stopImmediatePropagation();
        let ssNav = $(this).next(".smsNavBar__subNav__ul ");
        if (ssNav.hasClass("opened")) {
            ssNav.slideUp(400, function() { ssNav.removeClass("opened") });
        } else {
            ssNav.slideDown(400, function() { ssNav.addClass("opened") });
        }
        return false;
    });

    window.addEventListener('scroll', function(e) {
        if ($(".js-menu").hasClass("opened")) return false;
        if ($(window).scrollTop() > 62) {
            $(".js-fixedNavBar").addClass("visible");
        } else {
            $(".js-fixedNavBar").removeClass("visible");
        }
    });

}